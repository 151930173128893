import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { getUserList, personshareList } from '@/api/updateUserInfo/structure';
import ExportExcel from '@/components/ExportExcel/index';
import { excel } from '@/utils/excel';
import { getStructureListArr } from '@/api/PublicAPI';
export default {
  components: {
    Head: Head,
    ExportExcel: ExportExcel
  },
  data: function data() {
    // 时间范围初始值
    var _minTime = null;
    var _maxTime = null;
    return {
      // 系统教程
      tab: 1,
      activeName: 'first',
      course: this.$route.query.course,
      prohibit: false,
      searchShow: false,
      tableShow: false,
      // 系统教程
      // 埋点是否显示
      BuriedData1: [],
      Buried: {
        search_username: '',
        search_realname: '',
        search_create_time: '',
        search_structure_ids: '',
        search_auth_rule_title: ''
      },
      finalFollow: '',
      structure_id: '',
      start_follow_time: '',
      //时间（开始）
      end_follow_time: '',
      //时间（结束）
      typeShow: false,
      operatorShow: false,
      userNameshow: false,
      BelongingShow: false,
      operationDataShow: false,
      // 埋点是否显示
      pagesize: 15,
      page: 1,
      total: 0,
      loading: true,
      title: '流量共享统计',
      value1: '',
      buried: [],
      auth_rule_id: '',
      Studentleve_id: '',
      updateTime: '',
      bumenoptions: [],
      //部门
      Studentleve_list: [],
      Studentleve_Tos: [],
      Studentleve_time: '',
      //日期
      start_time: '',
      end_time: '',
      pickerOptions: {
        onPick: function onPick(time) {
          // 如果选择了只选择了一个时间
          if (!time.maxDate) {
            var timeRange = 30 * 24 * 60 * 60 * 1000; // 30天
            _minTime = time.minDate.getTime() - timeRange; // 最小时间
            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
            // 如果选了两个时间，那就清空本次范围判断数据，以备重选
          } else {
            _minTime = _maxTime = null;
          }
        },
        disabledDate: function disabledDate(time) {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if (_minTime && _maxTime) {
            return time.getTime() < _minTime || time.getTime() > _maxTime;
          }
        }
      },
      follow_Id: '',
      //创建人
      follow_name: '',
      follow_page: 1,
      follow_pagesize: 20,
      follow_Arr: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    // 控制表格滚动条
    var that = this;
    this.buried = this.$store.getters.JsonArr1;
    if (this.$store.getters.Atter.indexOf("查看") != -1) {
      this.tableShow = true;
    }
    if (this.$store.getters.Atter.indexOf("搜索") != -1) {
      this.searchShow = true;
    }
    if (this.course != 1) {
      this.buriedPoint('查看');
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this.$refs.multipleTable.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
      var sharing_time = [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)];
      this.start_time = String(sharing_time[0]).substr(0, 10);
      this.end_time = String(sharing_time[1]).substr(0, 10);
      this.GetList();
      this.addDate();
      this.followFun();
      this.Studentleve_time = [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)];
      this.getStructure();
    } else {}
  },
  methods: {
    // 埋点列表筛选
    operatorClick: function operatorClick() {
      //操作人
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    userNamesClick: function userNamesClick() {
      //用户名
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    noteAppendedClick: function noteAppendedClick() {
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    FinalFollowUpEs: function FinalFollowUpEs() {
      //最后跟进日期筛选
      this.page = 1;
      if (this.finalFollow == null) {
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.page, this.pagesize);
      } else {
        this.start_follow_time = this.finalFollow[0].toString().substring(0, 10);
        this.end_follow_time = this.finalFollow[1].toString().substring(0, 10);
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.page, this.pagesize);
      }
    },
    //组织部门
    structureclick: function structureclick(val) {
      this.page = 1;
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.Buried.search_structure_ids = adad;
      this.statisticalTable(this.page, this.pagesize);
    },
    // 埋点列表筛选
    handleClick: function handleClick() {
      if (this.activeName == 'second') {
        this.page = 1;
        this.statisticalTable(this.page, this.pagesize);
      }
      this.structure_id = '';
      this.finalFollow = '';
      this.typeShow = false;
      this.operatorShow = false;
      this.userNameshow = false;
      this.BelongingShow = false;
      this.operationDataShow = false;
      this.Buried = {
        search_username: '',
        search_realname: '',
        search_create_time: '',
        search_structure_ids: '',
        search_auth_rule_title: ''
      };
    },
    statisticalTable: function statisticalTable(page, pagesize) {
      var _this2 = this;
      this.Buried.page = page;
      this.Buried.pagesize = pagesize;
      this.Buried.auth_rule_pid = this.menuId;
      OperatorDataIndex(this.Buried).then(function (res) {
        _this2.BuriedData1 = res.data.data;
        _this2.total = res.data.total;
      });
    },
    getStructureSe: function getStructureSe() {
      var _this3 = this;
      //组织部门
      getStructureListArr().then(function (respomse) {
        _this3.bumenoptions = respomse.data;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.pagesize = val;
      this.statisticalTable(this.page, val);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.page = val;
      this.statisticalTable(val, this.pagesize);
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '操作人') {
        this.operatorShow = true;
      } else if (item === '用户名') {
        this.userNameshow = true;
      } else if (item === '操作日期') {
        this.operationDataShow = true;
      } else if (item === '操作人所属部门') {
        this.BelongingShow = true;
      } else if (item === '操作类型') {
        this.typeShow = true;
      }
    },
    Deletesshow: function Deletesshow(item) {
      this.page = 1;
      if (item === '操作人') {
        this.operatorShow = false;
        this.Buried.search_realname = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '用户名') {
        this.userNameshow = false;
        this.Buried.search_username = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作日期') {
        this.operationDataShow = false;
        this.Buried.search_create_time = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作人所属部门') {
        this.BelongingShow = false;
        this.structure_id = '';
        this.Buried.search_structure_ids = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作类型') {
        this.typeShow = false;
        this.Buried.search_auth_rule_title = '';
        this.statisticalTable(this.page, this.pagesize);
      }
    },
    buriedPoint: function buriedPoint(name) {
      var _this4 = this;
      this.buried.forEach(function (item) {
        if (item.title == name) {
          _this4.auth_rule_id = item.id;
        }
      });
      return this.auth_rule_id;
    },
    //获取列表
    GetList: function GetList() {
      var _this5 = this;
      var editForm = {
        create_stime: this.start_time,
        create_etime: this.end_time,
        structure_ids: this.Studentleve_id,
        auth_rule_id: this.auth_rule_id,
        create_uids: String(this.follow_name)
      };
      this.loading = true;
      personshareList(editForm).then(function (res) {
        var TurnoverRate = {
          prop: '成交率',
          label: '成交率'
        };
        res.data.tabletitle.unshift(TurnoverRate);
        var Efficiency = {
          prop: '有效率',
          label: '有效率'
        };
        res.data.tabletitle.unshift(Efficiency);
        var DataVolume = {
          prop: '数据量',
          label: '数据量'
        };
        res.data.tabletitle.unshift(DataVolume);
        var name = {
          prop: 'name',
          label: '创建人',
          width: '180',
          fixed: 'left'
        };
        res.data.tabletitle.unshift(name);
        res.data.data.map(function (res) {
          res.成交率 = res.成交率 + '%';
          res.有效率 = res.有效率 + '%';
          if (res.children != undefined) {
            res.children.map(function (item) {
              item.成交率 = item.成交率 + '%';
              item.有效率 = item.有效率 + '%';
              if (item.children != undefined) {
                item.children.map(function (evel) {
                  evel.成交率 = evel.成交率 + '%';
                  evel.有效率 = evel.有效率 + '%';
                  if (evel.children != undefined) {
                    evel.children.map(function (event) {
                      event.成交率 = event.成交率 + '%';
                      event.有效率 = event.有效率 + '%';
                    });
                  }
                });
              }
            });
          }
        });
        _this5.Studentleve_list = res.data.data;
        _this5.Studentleve_Tos = res.data.tabletitle;
        _this5.loading = false;
      });
    },
    // 当前日期
    addDate: function addDate() {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      };
      var newmonth = date.month > 10 ? date.month : '0' + date.month;
      var day = date.date > 10 ? date.date : '0' + date.date;
      this.updateTime = date.year + '-' + newmonth + '-' + day;
    },
    //数据统计范围树状图
    getStructure: function getStructure() {
      var _this6 = this;
      getStructureListArr({
        type: '1',
        get_type: '0'
      }).then(function (res) {
        _this6.bumenoptions = res.data;
      });
    },
    //时间
    DataTime: function DataTime() {
      var _this7 = this;
      if (this.Studentleve_time == null) {
        this.start_time = '';
        this.end_time = '';
        this.$nextTick(function () {
          _this7.GetList();
        });
      } else {
        var s_time = this.Studentleve_time[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.Studentleve_time[1].toString();
        this.end_time = e_time.substring(0, 10);
      }
    },
    //数据统计范围
    handleChange: function handleChange(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      this.Studentleve_id = share == ',' ? '' : share;
    },
    // 设置滚动条相关信息1111111111
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.multipleTable.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    //重置按钮
    replacement: function replacement() {
      var _this8 = this;
      this.value1 = '';
      this.teachername = ''; //顾问老师查询
      this.bumen = '';
      this.$nextTick(function () {
        _this8.GetList();
      });
    },
    numFilter: function numFilter(value) {
      if (this.Studentleve_list != '') {
        var realVal = parseFloat(value).toFixed(2);
        var Namval;
        Namval = realVal.split('.');
        if (Namval[0] != '0') {
          if (Namval[0].charAt(1) == '0') {
            Namval[0] = Namval[0].replace('0', '');
            if (Namval[1].charAt(0) == '0') {
              Namval[1] = Namval[1].replace('0', '');
              var NamvalS = Namval[0] + Namval[1];
              return NamvalS;
            } else {
              var _NamvalS = Namval[0] + Namval[1];
              return _NamvalS;
            }
          } else {
            if (Namval[1].charAt(0) == '0') {
              Namval[0] = Namval[0].replace('0', '');
              Namval[1] = Namval[1].replace('0', '');
              var _NamvalS2 = Namval[0] + Namval[1];
              return _NamvalS2;
            } else {
              var _NamvalS3 = Namval[0] + Namval[1];
              return _NamvalS3;
            }
          }
        } else {
          if (Namval[1].charAt(0) == 0) {
            var NamvalE = Namval[0] + '.' + Namval[1];
            return NamvalE;
          } else {
            return Namval[1];
          }
        }
      }
    },
    numFilterS: function numFilterS(value) {
      if (this.Studentleve_list != '') {
        var realVal = parseFloat(value).toFixed(2);
        var Namval;
        Namval = realVal.split('.');
        var NamvalE = Namval[0] + '.' + Namval[1];
        return NamvalE;
      }
    },
    //合计
    getSummaries: function getSummaries(param) {
      var _this9 = this;
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }
        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        if (!values.every(function (value) {
          return isNaN(value);
        })) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      });
      var shu = sums[1] - (sums[6] + sums[7]);
      var y = sums[4] + sums[5] + sums[18] + sums[19] + sums[13] + sums[14] + sums[15] + sums[16] + sums[17];
      var yxiao;
      var aaa;
      if (shu == 0) {
        yxiao = 0;
      } else {
        if (y == 0) {
          yxiao = 0;
        } else {
          yxiao = y / shu;
        }
      }
      if (sums[19] == 0) {
        aaa = 0;
      } else if (sums[1] == 0) {
        aaa = 0;
      } else {
        aaa = sums[19] / sums[1] * 100;
      }
      columns.forEach(function (column, index) {
        if (index === 2) {
          if (_this9.numFilter(yxiao) == '00' || _this9.numFilter(yxiao) == '0.00') {
            sums[index] = 0 + '%';
          } else if (_this9.numFilter(yxiao) == '') {
            sums[index] = 0 + '%';
          } else if (yxiao == '1') {
            sums[index] = '100%';
          } else if (yxiao == 0) {
            sums[index] = '0%';
          } else {
            sums[index] = _this9.numFilter(yxiao) + '%';
          }
          return;
        }
        if (index === 3) {
          if (_this9.numFilterS(aaa) == '00') {
            sums[index] = 0 + '%';
          } else if (aaa == 0) {
            sums[index] = '0%';
          } else if (aaa == 1) {
            sums[index] = '100%';
          } else {
            sums[index] = _this9.numFilterS(aaa) + '%';
          }
          return;
        }
      });
      return sums;
    },
    followFun: function followFun() {
      var _this10 = this;
      //创建人数据
      getUserList({
        get_type: '6',
        type: '0'
      }).then(function (res) {
        _this10.follow_Arr = res.data;
        // this.defaultArray.founder_Arr = res.data.data;
      });
    },
    followidEs: function followidEs(val) {
      //创建人
      this.follow_name = val;
      this.$refs.clearValue.query = '';
    },
    followMethod: function followMethod(val) {
      var _this11 = this;
      //创建人
      this.follow_name = val;
      getUserList({
        get_type: '6',
        type: '0',
        real_name: this.follow_name
      }).then(function (res) {
        _this11.follow_Arr = res.data;
      });
    },
    search: function search() {
      this.buriedPoint('搜索');
      this.GetList();
    }
  }
};