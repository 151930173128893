//引入依赖
import FileSaver from 'file-saver';
import XLSX from 'xlsx';

// id绑定的id，title表格名称
export var excel = function excel(id, title) {
  /* generate workbook object from table */
  //  判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去，
  var fix = document.querySelector('.el-table__fixed');
  var xlsxParam = {
    raw: true
  };
  var wb;
  if (fix) {
    wb = XLSX.utils.table_to_book(document.querySelector('#' + id).removeChild(fix), xlsxParam);
    document.querySelector('#' + id).appendChild(fix);
  } else {
    wb = XLSX.utils.table_to_book(document.querySelector('#' + id), xlsxParam);
  }

  //网上wb = XLSX.utils.table_to_book(document.querySelector('#'+id));直接这样写，如果存在固定列，导出的excel表格会重复两遍

  /* get binary string as output */
  var wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'array'
  });
  try {
    FileSaver.saveAs(new Blob([wbout], {
      type: 'application/octet-stream'
    }), title + '.xlsx');
  } catch (e) {
    if (typeof console !== 'undefined') console.log(e, wbout);
  }
  return wbout;
};